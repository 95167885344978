import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	Component,
	computed,
	effect,
	ElementRef,
	HostBinding,
	input,
	Input,
	OnDestroy,
	OnInit,
	signal,
	ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ErrorMessageMap, ErrorMessagePipe } from '../../pipes/error-message.pipe';
import { MarketService } from '../../services/market.service';
import { supportedMarkets } from '../../types/market.types';
import { IconComponent } from '../icon/icon.component';
import {
	Country,
	CountryCode,
	countryCodeToCountryMap,
	countryToCountryCodeMap,
	TelephoneInputFlagAttribute,
} from './types';

@Component({
	selector: 'cramo-input-tel',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		IconComponent,
		MatInputModule,
		FormsModule,
		MatSelectModule,
		ErrorMessagePipe,
	],
	templateUrl: './input-tel.component.html',
	styleUrls: ['./input-tel.component.scss'],
})
export class InputTelComponent implements OnInit, AfterViewInit, OnDestroy {
	public isRequired = signal(false);
	public country = signal<Country>('SE');
	public nationalNumber = signal('');
	public countryCodeLength = computed(() => countryToCountryCodeMap[this.country()].toString().length + 1); // "+ 1" needed due to the "+"

	/**
	 * Including country code
	 */
	public maxLength = input(35);
	public maxLengthExcludingCountryCode = computed(() => this.maxLength() - this.countryCodeLength());

	public countryCodeMap = countryToCountryCodeMap;
	public countryCodeList: TelephoneInputFlagAttribute[];

	protected formControl: FormControl;

	@HostBinding('class.has-error') public get hasError(): boolean {
		return this.formControl.touched && this.formControl.invalid;
	}

	@Input() public label: string;

	public errorMessageMap = input<ErrorMessageMap>();

	@ViewChild('input') private inputElement: ElementRef<HTMLInputElement>;
	@ViewChild('inputLabel') private labelElement: ElementRef<HTMLLabelElement>;

	private resizeObserver: ResizeObserver;

	@Input()
	public set control(control: AbstractControl) {
		if (!(control instanceof FormControl)) {
			throw new Error('The control must be an instance of FormControl');
		}

		this.isRequired.set(hasRequiredValidator(control));

		this.formControl = control;

		const phoneUtil = PhoneNumberUtil.getInstance();

		try {
			const phoneNumber = phoneUtil.parse(control.value);
			const countryCode = phoneNumber.getCountryCode().toString() as CountryCode;
			const nationalNumber = phoneNumber.getNationalNumber();

			this.country.set(countryCodeToCountryMap[countryCode]);
			this.nationalNumber.set(nationalNumber.toString());
		} catch (e) {
			this.country.set(this.marketService.currentMarket);
		}
	}

	constructor(private marketService: MarketService) {
		effect(() => {
			if (this.nationalNumber() === '') {
				this.formControl?.setValue?.('');
				return;
			}
			// removes non-numeric characters from nationalNumber, since spaces and dashes are allowed.
			const filteredNationalNumber = this.nationalNumber().replace(/[^\d]/g, '');
			const number = `+${countryToCountryCodeMap[this.country()]}${filteredNationalNumber}`;

			this.formControl?.setValue?.(number);
		});
	}

	ngOnInit(): void {
		this.countryCodeList = Object.entries(countryToCountryCodeMap)
			.map(([country, countryCode]: [Country, CountryCode]) => ({
				country,
				countryCode,
				flag: this.getFlag(country),
			}))
			.sort((a, b) => {
				const currentMarket = this.marketService.currentMarket;

				// Prioritize current market at the beginning of the list
				if (a.country === currentMarket) return -1;
				if (b.country === currentMarket) return 1;

				// Then sort by the supported markets
				const prioCountries = supportedMarkets as unknown as Country[];
				const aIsSupported = prioCountries.includes(a.country);
				const bIsSupported = prioCountries.includes(b.country);

				if (aIsSupported && !bIsSupported) {
					return -1;
				}

				if (!aIsSupported && bIsSupported) {
					return 1;
				}

				return Number(a.countryCode) - Number(b.countryCode);
			});
	}

	ngAfterViewInit() {
		this.setupResizeObserver();
	}

	ngOnDestroy() {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	}

	private setupResizeObserver() {
		this.resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const { width } = entry.contentRect;
				this.labelElement.nativeElement.style.maxWidth = `${width}px`;
			}
		});

		this.resizeObserver.observe(this.inputElement.nativeElement);
	}

	public onCountryCodeChange(country: Country) {
		this.country.set(country);

		if (this.nationalNumber().length > this.maxLengthExcludingCountryCode()) {
			const trimmedValue = this.trimToMaxLength(this.inputElement.nativeElement.value);
			this.inputElement.nativeElement.value = trimmedValue;
			this.nationalNumber.set(trimmedValue);
		}
	}

	public onNationalNumberChange(event: Event) {
		const inputElement = event.target as HTMLInputElement;
		const pattern = /^[0-9\s-]*$/;
		const { value } = inputElement;

		if (value.length > this.maxLengthExcludingCountryCode()) {
			const trimmedValue = this.trimToMaxLength(inputElement.value);
			inputElement.value = trimmedValue;
			return;
		}

		if (!pattern.test(value)) {
			// Filter out non-numeric and non-dash characters
			inputElement.value = value.replace(/[^0-9\s-]/g, '');
			return;
		}

		this.nationalNumber.set(value);
	}

	public getFlag(country: string) {
		return `/assets/icons/flags/${country}.svg`;
	}

	private trimToMaxLength(inputValue: string): string {
		const maxLengthExcludingCountryCode = this.maxLengthExcludingCountryCode();
		return inputValue.slice(0, maxLengthExcludingCountryCode);
	}
}

function hasRequiredValidator(control: FormControl): boolean {
	if (!control.validator) {
		return false;
	}

	const emptyValue: null = null; // A value that would fail the required validator
	const result = control.validator(new FormControl(emptyValue));

	// If result has a 'required' property, then the required validator is set
	return result?.required;
}
