<div class="country-code-wrapper">
	<label [ngClass]="{ 'has-value': nationalNumber() !== '' }" #inputLabel
		>{{ label }}
		@if (isRequired()) {
			<span class="mat-mdc-form-field-required-marker mdc-floating-label--required ng-star-inserted"></span>
		}
	</label>

	<mat-select [ngModel]="country()" (ngModelChange)="onCountryCodeChange($event)">
		<mat-select-trigger>
			<div class="option">
				<img [src]="getFlag(country())" class="flag" alt="" />
				<p>+{{ countryCodeMap[country()] }}</p>
			</div>
		</mat-select-trigger>

		<mat-option *ngFor="let countryCode of countryCodeList" [value]="countryCode.country">
			<div class="option">
				<img [src]="countryCode.flag" class="flag" alt="" />
				<p>+{{ countryCode.countryCode }}</p>
			</div>
		</mat-option>
	</mat-select>
</div>

<input [ngModel]="nationalNumber()" (input)="onNationalNumberChange($event)" (blur)="control.markAsTouched()" #input />

<div class="hint">
	@if (hasError) {
		<mat-error>{{ formControl.errors | errorMessage: errorMessageMap() }}</mat-error>
	}

	<div class="length-hint">{{ nationalNumber().length }}/{{ maxLengthExcludingCountryCode() }}</div>
</div>
